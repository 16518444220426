(function($) {
  // Site title
  /*
  wp.customize('blogname', function(value) {
    value.bind(function(to) {
      $('.brand').text(to);
    });
  });
  */

})(jQuery);
jQuery(function($){
	var searchRequest;
	$('.search-autocomplete').autoComplete({
		minChars: 1,
		renderItem: function (item, search){
			console.log(item);
        search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
        var re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
				var img = item['the_img'];

				var searchContainer = '<div class="autocomplete-suggestion" data-title="'+item['the_title']+'" data-url="'+item['url']+'" data-val="'+search+'"><a href="'+item['url']+'">';
				if (img != null) {
				searchContainer	+= '<img class="img-thumbnail" style="width:65px; height: inherit;" src="'+item['the_img']+'">';
				}
				searchContainer	+= item['the_title'].replace(re, "<b>$1</b>")+'</a></div>';

			return searchContainer;

    },
		onSelect: function(e, term, item){
      //  alert('Item "'+item.data('title')+' ('+item.data('url')+')" selected by '+(e.type == 'keydown' ? 'pressing enter' : 'mouse click')+'.');
			 window.location = item.data('url');
    },
		source: function(term, suggest){
			try {
				searchRequest.abort();
			}
			catch(e){}
			searchRequest = $.post(global.ajax,
				{
					search: term,
					action: 'search_site'
				},
				function(res) {suggest(res.data);
			});
		}
	});
});

/*
var xhr;
jQuery('.search-autocomplete').autoComplete({
    source: function(term, response){
        try { xhr.abort(); } catch(e){}
        xhr = $.post(global.ajax, { q: term, action: 'search_site' }, function(data){ response(data); });
    }
});
*/



jQuery(function($){
	$('#filter').submit(function(){
		var filter = $('#filter');
		$.ajax({
			url:filter.attr('action'),
			data:filter.serialize(), // form data
			type:filter.attr('method'), // POST
			beforeSend:function(xhr){
				filter.find('button').text('Processing...'); // changing the button label
			},
			success:function(data){
				filter.find('button').text('Apply filter'); // changing the button label back
				$('#response').html(data); // insert data
			}
		});
		return false;
	});
});

//portfolio_full


jQuery(function($){
$('#media_lib_form .br').click(function(){
var ajaxURL = global.ajax;
            // declaring an array
            var choices = [];

            $('.contents').remove();
            $('.filter-output').empty()

            $('input[type=checkbox]:checked').each(function() {
                if (!choices.hasOwnProperty(this.name))
                  //  choices[this.name] = [this.value];
										//choices.push($(this).val());
										choices.push($(this).val());

                else
                    choices.push(this.value);
										//choices.push($(this).val());
            });
            $.ajax({
                url: ajaxURL,
                type :'POST',
                data : {
                    'action' : 'call_post', // the php name function
                    'choices' : choices,
                },
								beforeSend:function(xhr){
										$('.filter-output').html('<div class="row"><div class="col-sm-6 col-md-3"><div class="movie--isloading"><div class="loading-image"></div>'+
      '<div class="loading-content">'+
        '<div class="loading-text-container">'+
         ' <div class="loading-main-text"></div>'+
          '<div class="loading-sub-text"></div>'+
        '</div>'+
       ' <div class="loading-btn"></div>'+
     ' </div>'+
   ' </div>'+
  '</div>'+
  '<div class="col-sm-6 col-md-3">'+
    '<div class="movie--isloading">'+
     ' <div class="loading-image"></div>'+
     ' <div class="loading-content">'+
       ' <div class="loading-text-container">'+
         ' <div class="loading-main-text"></div>'+
         ' <div class="loading-sub-text"></div>'+
        '</div>'+
        '<div class="loading-btn"></div>'+
     ' </div>'+
   ' </div>'+
  '</div>'+
  '<div class="col-sm-6 col-md-3">'+
    '<div class="movie--isloading">'+
     ' <div class="loading-image"></div>'+
     ' <div class="loading-content">'+
       ' <div class="loading-text-container">'+
         ' <div class="loading-main-text"></div>'+
         ' <div class="loading-sub-text"></div>'+
        '</div>'+
        '<div class="loading-btn"></div>'+
     ' </div>'+
   ' </div>'+
  '</div>'+
  '<div class="col-sm-6 col-md-3">'+
    '<div class="movie--isloading">'+
     ' <div class="loading-image"></div>'+
     ' <div class="loading-content">'+
       ' <div class="loading-text-container">'+
         ' <div class="loading-main-text"></div>'+
         ' <div class="loading-sub-text"></div>'+
        '</div>'+
        '<div class="loading-btn"></div>'+
     ' </div>'+
   ' </div>'+
  '</div></div>'); // changing the button label
									
									
									
									
									},
                success: function (result) {
									//console.log(result);
										$('.filter-output').empty();
                    $('.filter-output').append(result);
                    // just for test - success (you can remove it later)
                    //console.log(result);
                    //console.log(choices);

										var a = audiojs;
										a.events.ready(function() {
										 var a1 = a.createAll(
										 );
										});
										//
										//vimeo

										$('.vimeo-modal').click(function(event) {
						          event.preventDefault();
						          //console.log(termValue);
						          $('#video_modal').modal();
						          //grab the data id
						          var video_id = $(this).data('id');
						          // console.log(video_id);
						          $('#video_modal .modal-content').css({
						            display: 'inline-block'
						          }).animate({
						            "opacity": 1,
						            'min-height': "350px"
						          }, 'slow').html('<div class="rectangle-bounce"><img class="loader" src="http://samherbert.net/svg-loaders/svg-loaders/circles.svg" /></div>');
						          var htmlData = '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>' + '<div class="modal-body">';
						          htmlData += '<div class="video_wrapper"><iframe id="player' + video_id + '" src="https://player.vimeo.com/video/' + video_id + '?api=1&player_id=player' + video_id + '&amp;autoplay=true" width="630" height="354" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div>';
						          htmlData += '</div>';
						          $('#video_modal .modal-content').html(htmlData);
						          $('#video_modal').on('hidden.bs.modal', function() {
						            $(".modal-content").empty();
						          });
						        });



                },
                error: function(err){
                    // just for test - error (you can remove it later)
                    console.log(err);
                    console.log(choices);
                }
            });
        })
				});
